import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import Progress from 'nprogress-esm'

import generatedRoutes from '~pages'
import 'nprogress-esm/dist/style.css'

const router = createRouter({
  history: createWebHistory(),
  routes: setupLayouts(generatedRoutes),
})

router.beforeEach((to, from, next) => {
  Progress.start()
  next()
})

router.afterEach(() => {
  Progress.done()
})

export default router
