import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useStorage } from '@vueuse/core'
import { fingerprintControl } from '@/auth/control'

import { AtenaApi } from '@/api-client'
import axiosInstance from '@/api/axios'

export const useLoggedUserStore = defineStore(
  'loggedUser',
  () => {
    const item = ref()
    const currentSource = ref(undefined)
    const error = ref(null)

    async function authenticate({ username, password, control, usControl }) {
      try {
        const response = await axiosInstance.post('/authenticate', {
          username,
          password,
          control,
          usControl,
        })

        item.value = response.data
        error.value = null
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.length) {
          const errorMessage = err.response.data.errors[0].message
          error.value = errorMessage
          throw new Error(errorMessage)
        } else if (err.message) {
          error.value = err.message
          throw new Error(err.message)
        } else {
          console.error('Erro ao autenticar:', err)
        }
      }
    }

    return {
      item,
      currentSource,
      error,
      authenticate,
    }
  },
  {
    persist: [
      {
        paths: ['item', 'currentSource'],
        storage: localStorage,
      },
    ],
  },
)
