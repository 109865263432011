/* eslint-disable vue/no-ref-as-operand */
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import {
  parseISO,
  formatRelative,
  formatDuration,
  add,
  setDefaultOptions,
  differenceInMonths,
  isAfter,
  isBefore,
} from 'date-fns'

import axiosInstance from '@/api/axios'
import { ExamApi, PreOrderApi, ExamSourceApi, MaskApi } from '@/api-client'
import { useSpecialityStore } from '@/stores/speciality'
import { useCategoryStore } from '@/stores/category'
import { useAuth } from '@/auth/useAuth'

export const useExamStore = defineStore(
  'exam',
  () => {
    const { t } = useI18n()
    const item = ref({})
    const usersItems = ref([])
    const previousItems = ref(null)
    const info = ref({})
    const signed = ref({})
    const logos = ref([])
    const status = ref([])
    const items = ref([])
    const exams = ref([])
    const itemsByDocument = ref([])
    const sources = ref([])
    const noCheckedAlertItems = ref([])
    const itemsByCpf = ref([])
    const detail = ref(undefined)
    const report = ref(null)
    const reports = ref([])
    const files = ref([])
    const fileDetail = ref([])
    const physicians = ref([])
    const candidates = ref([])
    const hasSuccessfullyUpdated = ref(false)
    const toast = useToast()
    const templates = ref([])
    const repeatTemplates = ref([])
    const hasSuccess = ref(null)
    const response = ref(null)
    const specialityStore = useSpecialityStore()
    const categoryStore = useCategoryStore()
    const auth = useAuth()

    async function getAll({
      page = 0,
      pageSize = 20,
      examStartDate = '',
      examEndDate = '',
      categoryId,
      specialityId,
      statusId,
      orderColumn,
      orderBy,
      printed,
      urgent,
      urgencyAlert,
      notifications,
      cpf,
      sourceId,
    }) {
      try {
        if (!axiosInstance) {
          throw new Error('axiosInstance não definido')
        }
        if (!categoryStore || !specialityStore) {
          throw new Error('Stores categoryStore ou specialityStore não definidas')
        }

        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceExamsGet({
          page,
          pageSize,
          examStartDate,
          examEndDate,
          categoryId,
          specialityId,
          statusId,
          orderColumn,
          orderBy,
          printed,
          urgent,
          urgencyAlert,
          notifications,
        })
        await categoryStore.getAll()
        await specialityStore.getAll()

        let allExamIds = []
        if (cpf) {
          await getByDocument({ sourceId, cpf, page, pageSize, notifications })
          if (itemsByCpf && itemsByCpf.value) {
            allExamIds = itemsByCpf.value.map((item) => item.examId)
          } else {
            console.warn('itemsByCpf não está definido corretamente.')
          }
        }

        if (previousItems) {
          previousItems.value = items ? items.value : []
        }

        if (response && response.data && response.data.data) {
          items.value = response.data.data.data.map((item) => {
            return {
              ...item,
              printed: item.printed || false,
              name: item.patientName || '',
              statusId: item.status ? item.status.id : null,
              status: item.status ? item.status.name : '',
              category: item.category || '',
              linkClientId: item.category ? item.category.linkClientId : null,
              id: item.examId || '',
              date: item.creationDate || '',
            }
          })
        } else {
          console.warn('Resposta da API não contém os dados esperados.')
        }
      } catch (err) {
        console.error('Erro ao buscar exames:', err.message)
      }
    }
    async function getDetail({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreAdministratorExamsDetailsGet({ examId })

        detail.value = response.data
        const item = items.value.find((item) => item.examId === examId)

        if (item) {
          item.detail = {
            ...response.data,
            examStatusHistory: response.data.examStatusHistory ? response.data.examStatusHistory.reverse() : [],
          }
          if (!item.physicians) {
            const isAdmin = auth.user.roles && auth.user.roles.includes('admin')
            if (isAdmin) {
              await getPhysicians({ examId })
            }
          }
        }
      } catch (err) {
        console.error('Error fetching exam details:', err.message)
      }
    }

    async function getCandidatePatients({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamGetAssociationCandidatesExamIdGet({ examId })

        candidates.value = response.data.data
      } catch (err) {
        console.error('Error fetching exam details:', err.message)
      }
    }

    async function getPhysicians({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreAdministratorExamsPhysiciansForExamGet({ examId })

        physicians.value = response.data.data
        items.value.find((item) => item.examId == examId).physicians = response.data.data
        // items.value.find((item) => item.examId == examId).physicians = response.data.data.map((physician) => {
        //   return {
        //     ...physician,
        //     description: physician.username,
        //   }
        // })
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getAdminUrgencyAlerts({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreUrgencyAlertGetUrgencyAlertsForAdminGet({ examId })

        items.value.find((item) => item.examId == examId).alerts = response.data.data
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getNoCheckedAlerts({ sourceId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreUrgencyAlertGetAllNoCheckedAlertsGet({ sourceId })

        noCheckedAlertItems.value = response.data.data
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function postReport(examId, reportText, abnormal) {
      try {
        const response = await axiosInstance.post(`/FastReport/${examId}/Report`, {
          reportText,
          abnormal,
        })
        if (response.status === 200) {
          toast.success('Relatório enviado com sucesso!', { timeout: 3000 })
        } else {
          throw new Error('Erro ao enviar o relatório.')
        }
      } catch (err) {
        console.error('Erro ao enviar o relatório:', err.message)
        toast.error('Erro ao enviar o relatório.', { timeout: 3000 })
      }
    }

    async function saveObservation(data) {
      try {
        const { examId, content, creationDate, nameUser, newStatus } = data

        const response = await axiosInstance.post('/api/core/ExamSource/Exams/Observation', {
          examId,
          content,
          creationDate,
          nameUser,
          isStatusChanged: newStatus,
        })

        if (!response) {
          throw new Error('É necessário informar exame.')
        }

        const newItem = response.data.data
        let item = items.value.find((i) => i.examId === newItem.examId)

        if (item) {
          console.log(newItem.status.id)
          item = { ...item, ...newItem }

          if (item.detail) {
            item.detail = { ...item.detail, ...newItem }
            detail.value = { ...detail.value, ...item.detail }
          }
        }

        toast.success('Salvo com sucesso!', {
          timeout: 3000,
        })
      } catch (err) {
        console.log(err)
      }
    }
    async function changePhysician(data) {
      try {
        const { examId, physicianId } = data
        const response = await axiosInstance.put('/api/core/Administrator/Exams/ChangePhysician', {
          examId,
          physicianId,
        })

        if (!response) {
          throw new Error('É necessário informar exame.')
        }

        const item = items.value.find((i) => i.examId === examId)
        if (item) {
          item.physicianObjectId = data.physicianObjectId
        }
        console.log(item)
        toast.success('Médico alterado com sucesso.', {
          timeout: 3000,
        })
      } catch (err) {
        if (err.response?.data?.errors[0]?.message) {
          toast.error(err.response.data.errors[0].message, { timeout: 3000 })
        } else {
          toast.success('Médico alterado com sucesso!', { timeout: 3000 })
        }
      }
    }
    async function saveRetification(data) {
      try {
        const { examId, content, reasonId } = data
        const response = await axiosInstance.post('/api/core/Administrator/Exams/Retification', {
          examId,
          content,
          reasonId,
        })

        if (!response) {
          throw Error('É necessário informar exame.')
        }

        const item = items.value.find((i) => i.examId === data.examId)

        if (item) {
          item.detail.retifications.push(data)
          detail.value.retifications.push(data)
        }
      } catch (err) {
        if (err.response?.data?.errors[0]?.message) {
          toast.error(err.response.data.errors[0].message, { timeout: 3000 })
        }
      }
    }

    async function setUrgent({ examId }) {
      try {
        const response = await axiosInstance.put('/api/core/ExamSource/Exams/SetUrgentExam', { examId })
        if (!response) {
          throw new Error('no response')
        }
        if (!response.data.data) {
          items.value.find((item) => item.examId == examId).urgent = true
          toast.success(t('examUpdatedSuccess', { examId: examId }), { timeout: 3000 })
        } else if (response.data.errors) {
          toast.error(response.data.errors[0].message, { timeout: 3000 })
        }
      } catch (err) {
        console.error('Erro ao setUrgent:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getByDocument({ sourceId, cpf, page, pageSize = 999, notifications }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceExamsByDocumentGet({
          sourceId,
          cpf,
          page,
          pageSize,
          notifications,
        })
        if (!response) {
          throw new Error('O valor do campo CPF não é válido.')
        }
        itemsByCpf.value = response.data.data.data
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getReport({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceExamsExamIdReportGet({ examId }, { responseType: 'blob' })

        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], { type: contentType })

        const logoUrl = window.URL.createObjectURL(blob)
        report.value = logoUrl
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        const isExpired = err.message == 'response is undefined'
        toast.info(isExpired ? 'Laudo expirado' : err.message, { timeout: 5000 })
        if (isExpired) throw new Error()
      }
    }

    async function getReports({ examsId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceExamsReportsGet({ examsId }, { responseType: 'blob' })
        reports.value = response.data
        console.log(response)
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }
    async function getQueueFirstItem({ examId }) {
      try {
        const response = await axiosInstance.get(`/api/core/Physician/Exams/GetBaseItem`, {
          params: { examId },
        })
        if (!response.data) {
          toast.success(t('noPendingExams'), { timeout: 3000 })
        } else {
          item.value = response.data.data
          if (item.value.hasInformation) {
            await getInformation({ examId: item.value.examId })
            item.value.informations = info.value
          }
        }
      } catch (err) {
        console.error('Erro ao getQueueFirstItem:', err.message)
      }
    }

    async function getAdminUsers({ page, pageSize }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiAtenaAdminUserGet({ page, pageSize })
        usersItems.value = response.data.docs
      } catch (err) {
        console.error('Error:', err.message)
      }
    }

    async function getInformation({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamsInformationGet({ examId })
        info.value = response.data.data
        const newInformation = response.data.data
        const item = items.value.find((i) => i.examId === examId)

        if (item) {
          item.detail.informations = newInformation
        }
      } catch (err) {
        console.error('Erro ao getInformation:', err.message)
      }
    }

    async function getCanAlertBySource({ examId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreUrgencyAlertCanAlertBySourceGet({ examId })
        info.value = response.data.data
        const newInformation = response.data.data
        const item = items.value.find((i) => i.examId === examId)

        if (item) {
          item.detail.informations = newInformation
        }
      } catch (err) {
        console.error('Erro ao getInformation:', err.message)
      }
    }

    async function saveInformation({ examId, text }) {
      try {
        const response = await axiosInstance.post('/api/core/Exams/Information', {
          examId,
          text,
        })

        if (response.status === 201) {
          toast.success('Enviado com sucesso', { timeout: 3000 })
        }

        const item = items.value.find((item) => item.examId === examId)

        if (item) {
          // Ensure originalStatus is defined
          item.originalStatus = item.originalStatus || {}

          if (item.statusId == 26 || item.originalStatus.id == 26) {
            item.statusId = 22
            item.originalStatus.id = 22
            item.originalStatus.name = 'Aguardando laudo'
          } else {
            item.statusId = 9
            item.originalStatus.id = 9
            item.originalStatus.name = 'Laudando'
          }
        }

        if (info.value && response.data.data) {
          info.value = response.data.data
        } else {
          console.error('Info or response data is undefined')
        }
      } catch (err) {
        console.error(err)
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response.data.errors[0].message, { timeout: 3000 })
        } else {
          toast.error('Requisição inválida. Verifique os dados e tente novamente.', { timeout: 3000 })
        }
      }
    }

    async function postExamAssociate({ newExamId, oldExamId }) {
      try {
        const response = await axiosInstance.post('/api/core/Exam/Associate', {
          newExamId,
          oldExamId,
        })

        if (!response || ![200, 201].includes(response.status)) {
          throw new Error('O exame não é válido.')
        }
        toast.success(t('success'), { timeout: 3000 })
        info.value = response.data.data
      } catch (err) {
        toast.error(err.message, { timeout: 3000 })
      }
    }
    async function assignPhysician({ examId, physicianId, physicianName }) {
      try {
        const response = await axiosInstance.post('/api/core/Administrator/Exam/AssignExamToPhysician', {
          examId,
          physicianId,
        })
        const item = items.value.find((item) => item.examId === examId)

        if (item) {
          item.physicianId = physicianId
          item.physicianName = physicianName
          item.statusId = 9
          item.originalStatus.id = 9
          item.originalStatus.name = 'Laudando'
        }

        toast.success('Médico associado com sucesso.', {
          timeout: 3000,
        })
      } catch (err) {
        if (err.response?.data?.errors[0]?.message) {
          toast.error(err.response.data.errors[0].message, { timeout: 3000 })
        } else {
          toast.error('Requisição inválida. Verifique os dados e tente novamente.', { timeout: 3000 })
        }
      }
    }

    async function sendRadioReport({ examId, reportText, abnormal }) {
      try {
        const response = await axiosInstance.post(`api/core/FastReport/${examId}/Report`, {
          reportText,
          abnormal,
        })
        if (response.status === 200 || response.status === 201) {
          hasSuccess.value = response.data.data
          exams.value = exams.value.filter((exam) => exam.examId !== examId)
          toast.success('Laudo enviado com sucesso!', { timeout: 3000 })
        } else {
          throw new Error('Erro ao enviar o relatório.')
        }
      } catch (err) {
        toast.error('Erro ao enviar o relatório.', { timeout: 3000 })
      }
    }
    async function sendRepeatReport({ examId, reason }) {
      try {
        const response = await axiosInstance.post(`api/core/FastReport/${examId}/Repeat`, {
          reason,
        })
        if (response.status === 200 || response.status === 201) {
          hasSuccess.value = response.data.data
          exams.value = exams.value.filter((exam) => exam.examId !== examId)
          toast.success('Exame repetido com sucesso!', { timeout: 3000 })
        } else {
          throw new Error('Erro ao repetir o exame.')
        }
      } catch (err) {
        toast.error('Erro ao repetir o exame.', { timeout: 3000 })
      }
    }

    async function reportExam({ examId, description, status, masksids }) {
      try {
        const response = await axiosInstance.post('/api/core/Physician/Exams/Laudar/InsertMedicalReport', {
          examId,
          description,
          status,
          masksids,
        })
        if (response.data.data.existExam == true) {
          hasSuccessfullyUpdated.value = true
        }
      } catch (err) {
        console.error('Erro ao repeatExam:', err.message)
      }
    }

    async function addUrgencyAlert({ examId, description }) {
      try {
        const response = await axiosInstance.post('/api/core/UrgencyAlert/Add', {
          examId,
          description,
        })
        const exam = exams.value.find((item) => item.examId === examId)
        if (exam) {
          exam.hasUrgencyAlert = true
        }
      } catch (err) {
        console.error('Erro ao repeatExam:', err.message)
      }
    }

    async function urgencyAlertPostIknow({ urgencyAlertID }) {
      try {
        const response = await axiosInstance.put('/api/core/UrgencyAlert/PostIKnow', {
          urgencyAlertID,
        })
      } catch (err) {
        console.error('Erro ao repeatExam:', err.message)
      }
    }

    async function repeatExam({ examId, reason, reasonsCodes }) {
      try {
        const response = await axiosInstance.put('/api/core/Physician/Exams/RepeatExam', {
          examId,
          reason,
          reasonsCodes,
        })
        if (response.data.data.existExam == true) {
          hasSuccessfullyUpdated.value = true
        }
      } catch (err) {
        console.error('Erro ao repeatExam:', err.message)
      }
    }

    async function replaceReport({ examId }) {
      try {
        const response = await axiosInstance.post('/api/core/Administrator/Exam/ReplaceReportDocument', {
          examId,
        })

        const item = items.value.find((item) => item.examId === examId)

        if (item) {
          item.statusId = 22
          item.originalStatus.id = 22
        }
        toast.success('Exame refeito com sucesso!', { timeout: 3000 })
      } catch (err) {
        console.log(err)
        if (err.response?.data?.errors[0]?.message) {
          toast.error(err.response.data.errors[0].message, { timeout: 3000 })
        } else {
          toast.error('Requisição inválida. Verifique os dados e tente novamente.', { timeout: 3000 })
        }
      }
    }

    async function cancelExam({ examId, reason }) {
      try {
        const response = await axiosInstance.put('/api/core/Administrator/Exams/Cancel', {
          examId,
          reason,
        })
      } catch (err) {
        console.error('Erro ao repeatExam:', err.message)
      }
    }

    async function getSignedUrl({ examId, md5, extension, fileType, fileName }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreAdministratorExamSignedUrlGet({
          examId,
          md5,
          extension,
          fileType,
          fileName,
        })

        signed.value = response.data.data
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getSignedUrl2({ examFileId, md5, extension, fileType, fileName }) {
      try {
        const response = await axiosInstance.put('/api/core/Administrator/Exams/SetSuccessUploadStatus', null, {
          params: {
            examFileId,
            md5,
            extension,
            fileType,
            fileName,
          },
        })

        signed.value = response.data.data
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getSourceLogo({ sourceId }) {
      try {
        const examSourceApi = new ExamSourceApi(undefined, undefined, axiosInstance)
        const response = await examSourceApi.apiCoreExamSourceExamSourceLogoGet({ sourceId }, { responseType: 'blob' })

        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], { type: contentType })

        const logoUrl = window.URL.createObjectURL(blob)
        logos.value.push({ sourceId, logoUrl })
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getExamByDocument({ page, pageSize = 999, examStartDate, examEndDate, cpf }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceExamsByDocumentGet({
          page,
          pageSize,
          examStartDate,
          examEndDate,
          cpf,
        })
        previousItems.value = itemsByDocument.value

        itemsByDocument.value = response.data.data.data.map((item) => {
          return {
            ...item,
            printed: item.printed,
            name: item.patientName,
            statusId: item.status.id,
            status: item.status.name,
            id: item.examId,
            date: item.creationDate,
          }
        })
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getRadioExamsList() {
      try {
        const response = await axiosInstance.get('api/core/FastReport')
        if (response && response.data) {
          exams.value = response.data.data
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        console.error('Erro ao buscar lista de exames:', error.message)
        throw new Error('Erro ao buscar lista de exames: ' + error.message)
      }
    }

    async function getNotes(examId) {
      try {
        const response = await axiosInstance.get(`api/core/FastReport/${examId}/Notes`)
        if (response && response.data) {
          // notes.value = response.data.data.notes

          const exam = exams.value.find((item) => item.examId === examId)
          if (exam) {
            exam.notes = response.data.data.notes
          }
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        console.error('Erro ao buscar lista de exames:', error.message)
        throw new Error('Erro ao buscar lista de exames: ' + error.message)
      }
    }

    async function getExamFiles(examFile, newWindow = true) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)

        const response = await examApi.apiCoreAdministratorExamsFileGet(
          { examFileId: examFile.examFileId },
          { responseType: 'blob' },
        )

        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], { type: contentType })

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `${t('document')}-${examFile.examFileId}.${examFile.extension}`
        if (newWindow) {
          link.click()
        } else {
          return url
        }
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getFiles({
      sourceId,
      examId,
      patientName,
      categoryId,
      specialityId,
      statusId,
      page,
      pageSize = 999,
      examStartDate,
      examEndDate,
      orderColumn,
      orderBy,
      printed,
      urgent,
      urgencyAlert,
      notifications,
    }) {
      try {
        await specialityStore.getAll()
        const examApi = new ExamApi(undefined, undefined, axiosInstance)

        const response = await examApi.apiPtmsyncPreOrderGet({
          sourceId,
          examId,
          patientName,
          categoryId,
          specialityId,
          statusId,
          page,
          pageSize,
          examStartDate,
          examEndDate,
          orderColumn,
          orderBy,
          printed,
          urgent,
          urgencyAlert,
          notifications,
        })
        files.value = response.data.data.data.map((item) => {
          return {
            ...item,
            file: (item.files[0] && item.files[0].originalFilename) || '',
            date: formatRelative(parseISO(item.creationDate), new Date()),
          }
        })
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getFileDetail({ preOrderId }) {
      try {
        const preOrderApi = new PreOrderApi(undefined, undefined, axiosInstance)
        const response = await preOrderApi.apiPtmsyncPreOrderDetailsGet({ preOrderId })
        const item = response.data.data
        fileDetail.value = {
          ...item,
          ...item.files[0],
          date: formatRelative(parseISO(item.creationDate), new Date()),
        }
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getTemplates({ specialityId }) {
      try {
        const maskApi = new MaskApi(undefined, undefined, axiosInstance)
        const response = await maskApi.apiCorePhysicianMaskSpecialityIdGet({ specialityId })
        templates.value = response.data.data.map((chip) => {
          return {
            title: chip.name,
            body: chip.text,
            type: chip.isChanged ? 'anormal' : 'normal',
            ...chip,
          }
        })
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getTemplatesRepetir({ specialityId }) {
      try {
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCorePhysicianExamsReasonsToRepeatExamBySpecialityIdGet({ specialityId })
        repeatTemplates.value = response.data.data.map((chip) => {
          return {
            title: chip.name,
            body: chip.text,
            ...chip,
          }
        })
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function putFileDetail(data) {
      try {
        const response = await axiosInstance.put('/api/ptmsync/PreOrder/', data)
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function cancelPreOrder(preOrderId) {
      try {
        const response = await axiosInstance.put(`/api/ptmsync/PreOrder/cancel/${preOrderId}`)
        return response.data
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        throw err
      }
    }

    async function getPreOrderFileById({ preOrderId }) {
      try {
        const response = await axiosInstance.get(`/api/ptmsync/PreOrder/SignedUrl?fileId=${preOrderId}`)

        const item = response.data.data
        fileDetail.value = {
          ...item,
          ...item.files[0],
          date: formatRelative(parseISO(item.creationDate), new Date()),
        }
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function activationCode(data) {
      try {
        const response = await axiosInstance.post('/api/ptmsync/ActivationCode/Create', data)
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function getPreOrderStatus() {
      try {
        const preOrderApi = new PreOrderApi(undefined, undefined, axiosInstance)
        const response = await preOrderApi.apiPtmsyncPreOrderStatusGet()
        // console.log(response.data)
        status.value = response.data.data
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, { timeout: 3000 })
      }
    }

    async function sendReview(examId, review) {
      try {
        const response = await axiosInstance.post('/api/core/Exams/SendReview', {
          examId,
          review,
        })
        toast.success('Exame enviado para revisão com sucesso!', { timeout: 3000 })
      } catch (err) {
        console.log(err.response)
      }
    }

    async function updatePatientName(examId, name) {
      try {
        const response = await axiosInstance.put(`/api/core/ExamSource/Exams/${examId}`, {
          name,
        })
        response.value = response.data
        const item = items.value.find((item) => item.examId === examId)

        if (item) {
          item.name = name
        }

        toast.success('Nome do paciente atualizado com sucesso!', { timeout: 3000 })
      } catch (err) {
        if (err.response?.data?.errors[0]?.message) {
          toast.error(err.response.data.errors[0].message, { timeout: 3000 })
        } else if (err.response?.status === 400) {
          toast.error('Requisição inválida. Verifique os dados e tente novamente.', { timeout: 3000 })
        } else {
          toast.error('Erro ao atualizar o nome do paciente.', { timeout: 3000 })
        }
      }
    }

    async function getAllSources(payload) {
      try {
        const examSourceApi = new ExamSourceApi(undefined, undefined, axiosInstance)
        const response = await examSourceApi.apiCoreAdministratorExamSourceSearchGet({
          ...payload,
          pageSize: 9999,
        })

        sources.value = response.data.data.items
        // sources.value = response.data.data.data.map((item) => {
        //   return {
        //     ...item,
        //     printed: item.printed,
        //     name: item.patientName,
        //     statusId: item.status.id,
        //     status: item.status.name,
        //     category: item.category,
        //     linkClientId: item.category.linkClientId,
        //     id: item.examId,
        //     date: item.creationDate,
        //   }
        // })
      } catch (err) {
        console.error('Erro ao autenticar:', err.message)
      }
    }

    async function getCurrentUserSources() {
      try {
        const examSourceApi = new ExamSourceApi(undefined, undefined, axiosInstance)
        const response = await examSourceApi.apiCoreExamSourceExamSourceCurrentUserSourcesGet()
        items.value = response.data.data
      } catch (err) {
        console.error('Erro ao processar requisição:', err.message)
        toast.error(err.message, {
          timeout: 3000,
        })
      }
    }

    async function getUrgencyAlert(examId) {
      try {
        const response = await axiosInstance.get(`/api/core/Exams/${examId}`)
        if (response && response.data) {
          return response.data.data.hasUrgencyAlert
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        console.error('Erro ao buscar alerta de urgência:', error.message)
        throw new Error('Erro ao buscar alerta de urgência: ' + error.message)
      }
    }

    return {
      item,
      previousItems,
      items,
      exams,
      itemsByDocument,
      itemsByCpf,
      report,
      updatePatientName,
      urgencyAlertPostIknow,
      reports,
      files,
      cancelExam,
      replaceReport,
      fileDetail,
      status,
      usersItems,
      getAll,
      getDetail,
      getRadioExamsList,
      getNotes,
      detail,
      getPhysicians,
      getAdminUrgencyAlerts,
      getNoCheckedAlerts,
      saveObservation,
      saveRetification,
      setUrgent,
      getByDocument,
      getReport,
      getReports,
      getFiles,
      postExamAssociate,
      getCandidatePatients,
      physicians,
      candidates,
      getFileDetail,
      putFileDetail,
      changePhysician,
      getPreOrderStatus,
      getExamByDocument,
      getExamFiles,
      activationCode,
      getInformation,
      saveInformation,
      assignPhysician,
      info,
      getSourceLogo,
      logos,
      reportExam,
      sendRadioReport,
      sendRepeatReport,
      addUrgencyAlert,
      repeatExam,
      hasSuccessfullyUpdated,
      getSignedUrl,
      getSignedUrl2,
      signed,
      sendReview,
      getTemplates,
      getTemplatesRepetir,
      getCurrentUserSources,
      templates,
      repeatTemplates,
      getAdminUsers,
      noCheckedAlertItems,
      getAllSources,
      sources,
      response,
      hasSuccess,
      getCanAlertBySource,
      getQueueFirstItem,
      cancelPreOrder,
      getPreOrderFileById,
      getUrgencyAlert,
    }
  },
  {
    persist: [
      {
        paths: ['detail', 'itemsByCpf', 'report', 'reports'],
        storage: localStorage,
      },
      {
        paths: ['detail', 'itemsByCpf', 'report', 'reports'],
        storage: localStorage,
      },
    ],
  },
)
