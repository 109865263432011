/* eslint-disable import/order */
import '@/assets/scss/app.scss'
import vuetify from '@/plugins/vuetify'
import { createApp } from 'vue'
import { createAuth } from './auth'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import socketPlugin from '@/plugins/socketPlugin'
import axiosInstance from './api/axios'
import i18n from './plugins/i18n'
import Toast, { PluginOptions } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueApexCharts from 'vue3-apexcharts'

const app = createApp(App)
const pinia = createPinia()

pinia.use(
  createPersistedState({
    storage: localStorage,
    auto: true,
  }),
)

const auth = createAuth({
  router,
  logoutRedirectRoute: { name: 'index' },
  autoConfigureNavigationGuards: true,
  axios: {
    instance: axiosInstance,
    autoAddAuthorizationHeader: true,
    authorizationHeaderPrefix: '',
  },
})

app.use(Toast, {
  position: 'top-left',
})

app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(
  socketPlugin,
  {
    connection: import.meta.env.VITE_API_URL,
    options: {
      path: '/api/socket/socket.io',
      reconnection: true,
      transports: ['websocket'],
    },
  },
  // @ts-ignore
  pinia, // Passa o Pinia para o plugin
)
app.use(auth)
app.use(i18n)
app.component('apexchart', VueApexCharts)
app.mount('#app')
// @ts-ignore
window.$app = app
