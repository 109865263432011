interface WebGLFingerprint {
  vendor: string
  renderer: string
}

interface BrowserFingerprint {
  userAgent: string
  platform: string
  languages: readonly string[]
  colorDepth: number
  timezone: string
  plugins: string[]
  doNotTrack: string | null
  webglFingerprint: WebGLFingerprint | null
}

export const fingerprintControl = {
  async encryptFingerprint(data: string, key: CryptoKey): Promise<string> {
    const encoder = new TextEncoder()
    const encodedData = encoder.encode(data)
    const iv = new Uint8Array([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

    const encryptedData = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, encodedData)
    const encryptedArray = new Uint8Array(encryptedData)
    const encryptedBase64 = btoa(String.fromCharCode(...encryptedArray))

    return encryptedBase64
  },

  getWebGLFingerprint(): WebGLFingerprint | null {
    try {
      const canvas = document.createElement('canvas')
      const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
      if (!gl) return null

      // Corrigindo o tipo para WebGLRenderingContext
      const webGLContext = gl as WebGLRenderingContext
      const debugInfo = webGLContext.getExtension('WEBGL_debug_renderer_info')
      if (!debugInfo) return null

      return {
        vendor: webGLContext.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
        renderer: webGLContext.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
      }
    } catch (e) {
      return null
    }
  },

  async getEncryptedFingerprint(): Promise<string> {
    const fingerprint: BrowserFingerprint = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      languages: navigator.languages,
      colorDepth: window.screen.colorDepth,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      plugins: Array.from(navigator.plugins).map((plugin) => plugin.name),
      doNotTrack: navigator.doNotTrack || 'unspecified',
      webglFingerprint: this.getWebGLFingerprint(),
    }

    const serializedFingerprint = JSON.stringify(fingerprint)
    const cryptoKey = await this.getKey()
    const encryptedData = await this.encryptFingerprint(serializedFingerprint, cryptoKey)
    return encryptedData
  },

  async getKey(): Promise<CryptoKey> {
    const jwkKey = {
      kty: 'oct',
      k: 'cioobdtFMHQZDMmjP9493PyqprzLnDarU4dFG4j2wyk',
      alg: 'A256GCM',
      ext: true,
    }

    const key = await crypto.subtle.importKey('jwk', jwkKey, { name: 'AES-GCM' }, true, ['encrypt', 'decrypt'])

    return key
  },

  getOrCreateCookie(): string {
    let uuid = this.getCookie('xx')
    if (this.getCookie('xx') === null) {
      uuid = this.generateUUID()
      this.setCookie('xx', uuid, 90)
    }
    return uuid as string
  },

  setCookie(name: string, value: string, days: number): void {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 10000)
    const expires = 'expires=' + date.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
  },

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (char) {
      const random = (Math.random() * 16) | 0
      const value = char === 'x' ? random : (random & 0x3) | 0x8
      return value.toString(16)
    })
  },

  getCookie(name: string): string | null {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1))
      }
    }
    return null
  },
}
