import { App, computed, reactive, readonly, ref, inject } from 'vue'
import { useToast } from 'vue-toastification'
import { fingerprintControl } from './control'

import { setupDevtools } from './devtools'
import { configureAuthorizationHeaderInterceptor } from './interceptors'
import { configureNavigationGuards } from './navigationGuards'
import { ANONYMOUS_USER, AuthOptions, AuthPlugin, RequiredAuthOptions, User } from './types'

import { useLoggedUserStore } from '@/stores/loggedUser'
import { SOCKET_KEY } from '@/plugins/socketPlugin'

export let authInstance: AuthPlugin | undefined = undefined

function setupAuthPlugin(options: RequiredAuthOptions): AuthPlugin {
  const toast = useToast()
  const router = options.router
  const accessToken = ref<string>()
  const user = ref<User>({ ...ANONYMOUS_USER })
  const userFullName = computed(() => {
    let fullname = user.value.firstName
    if (user.value.lastName) {
      fullname += ` ${user.value.lastName}`
    }
    return fullname
  })

  const isAuthenticated = computed(() => {
    // @ts-ignore
    if (!user.value?.expires) return false
    // @ts-ignore
    const expireDate = new Date(user.value.expires)
    return expireDate > new Date()
  })

  // Restore user session from localStorage if available
  const loggedUserStore = useLoggedUserStore()
  if (loggedUserStore.item) {
    user.value = loggedUserStore.item
    if (user.value) {
      // @ts-ignore
      accessToken.value = user.value.token
    } else {
      accessToken.value = undefined
    }
  }

  // const app_env = ref(import.meta.env.VITE_APP_ENV)
  async function login({ username, password }: { username: string; password: string }) {
    try {
      const control = await fingerprintControl.getEncryptedFingerprint()
      const usControl = fingerprintControl.getOrCreateCookie()

      await loggedUserStore.authenticate({ username, password, control, usControl })
      user.value = loggedUserStore.item
      // @ts-ignore
      accessToken.value = user.value.token
      // @ts-ignore
      isAuthenticated.value = true

      // Verifica se o usuário precisa alterar a senha antes de qualquer outra coisa
      // @ts-ignore
      if (user.value.isMustChangePassword) {
        toast.info('Você deve alterar sua senha antes de continuar.', { timeout: 3000 })
        await router.push({ name: 'reset-password' })
        return
      }

      // Se o usuário tiver papel "source", garantir que definimos o currentSource
      // @ts-ignore
      if (loggedUserStore.currentSource === undefined) {
        if (loggedUserStore.item.source && loggedUserStore.item.source.length > 0) {
          loggedUserStore.currentSource = loggedUserStore.item.source[0].id
        } else {
          loggedUserStore.currentSource = undefined
        }
      }
      // Fluxo de redirecionamento baseado no papel
      const redirectTo = (() => {
        // @ts-ignore
        if (user.value.roles && user.value.roles.includes('physician')) {
          // @ts-ignore
          const { onlyReports, listExams } = user.value.physician || {}
          if (!onlyReports && !listExams) {
            toast.warning('Você não possui nenhuma função habilitada', { timeout: 9000 })
            logout()
            return '/login'
          }
          if (onlyReports && !listExams) {
            return '/exams-report-medico'
          }
          if (listExams) {
            return '/radio-report-medico'
          }
          toast.error('Erro de configuração. Entre em contato com o suporte.', { timeout: 9000 })
          logout()
          return '/login'
        }
        // @ts-ignore
        if (user.value.roles && user.value.roles.includes('analyst')) {
          return '/analyst'
        }
        // @ts-ignore
        if (user.value.roles && user.value.roles.includes('admin')) {
          return '/admin'
        }

        // Padrão para outras funções.
        return '/operational'
      })()

      toast.success('Autenticado com sucesso', { timeout: 3000 })
      await router.push(redirectTo)
    } catch (error) {
      console.error('Erro durante o login:', error)
      throw new Error(error)
    }
  }

  async function logout() {
    try {
      // const socket = inject(SOCKET_KEY)
      // if (socket) {
      //   socket.emit('force-disconnect', user)
      // }

      // Limpa os dados do usuário
      user.value = { ...ANONYMOUS_USER }
      accessToken.value = undefined

      // Limpa todos os dados armazenados
      localStorage.clear()
      sessionStorage.clear()

      // Redireciona para a página inicial
      await router.push({ name: 'index' })
    } catch (error) {
      console.error('Erro ao fazer logout:', error)
      toast.error('Erro ao fazer logout', {
        timeout: 3000,
      })
    }
  }

  /*
   * "reactive" unwraps 'ref's, therefore using the .value is not required.
   * E.g: from "auth.isAuthenticated.value" to "auth.isAuthenticated"
   * but when using destructuring like: { isAuthenticated } = useAuth() the reactivity over isAuthenticated would be lost
   * this is not recommended but in such case use toRefs: { isAuthenticated } = toRefs(useAuth())
   * See: https://v3.vuejs.org/guide/reactivity-fundamentals.html#ref-unwrapping
   * And: https://v3.vuejs.org/guide/reactivity-fundamentals.html#destructuring-reactive-state
   */
  const unWrappedRefs = reactive({
    isAuthenticated,
    accessToken,
    user,
    userFullName,
    login,
    logout,
  })
  // @ts-ignore
  return readonly(unWrappedRefs)
}

const defaultOptions = {
  logoutRedirectRoute: '/',
  loginRouteName: 'index',
  autoConfigureNavigationGuards: true,
}

export function createAuth(appOptions: AuthOptions) {
  // Fill default values to options that were not received
  // @ts-ignore
  const options: RequiredAuthOptions = { ...defaultOptions, ...appOptions }

  return {
    install: (app: App): void => {
      authInstance = setupAuthPlugin(options)
      app.config.globalProperties.$auth = authInstance

      if (options.autoConfigureNavigationGuards) {
        configureNavigationGuards(options.router, options)
      }

      if (options.axios?.autoAddAuthorizationHeader) {
        configureAuthorizationHeaderInterceptor(options.axios.instance, options.axios.authorizationHeaderPrefix)
      }

      if (import.meta.env.DEV) {
        // until it gets fixed in devtools
        setupDevtools(app, authInstance)
      }
    },
  }
}
