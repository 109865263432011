<route lang="yaml">
alias: /
meta:
  layout: default
</route>

<template>
  <v-card class="text-center">
    <h2>{{ $t('login.digital-health-center') }}</h2>
    <v-card-subtitle>{{ $t('login.enter-your-credentials') }}</v-card-subtitle>
    <LanguageSwitcher class="pt-4"></LanguageSwitcher>
    <v-form class="form" @submit.prevent="submitForm">
      <v-card-text>
        <v-text-field
          v-model="email"
          data-cy="email"
          :label="t('login.username')"
          required
          color="primary"
          prepend-inner-icon="mdi-account"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          v-model="password"
          data-cy="password"
          :label="$t('login.password')"
          color="primary"
          :type="showPassword ? 'text' : 'password'"
          required
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          append-inner-icon="mdi-eye"
          @click:append-inner="togglePasswordVisibility"
        ></v-text-field>
        <v-btn type="submit" class="submit-button btn-primary" prepend-icon="mdi-login">{{ $t('login.enter') }}</v-btn>
        <router-link :to="{ name: 'forgot-password' }" class="forgot-password-link">{{
          $t('login.forgotPasswordTitle')
        }}</router-link>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script setup>
import { ref } from 'vue'
import { useAuth } from '@/auth/useAuth'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const auth = useAuth()
const toast = useToast()
const hasLoaded = ref(false)

const email = ref('')
const password = ref('')
const isLoading = ref(false)

const submitForm = async () => {
  try {
    showPassword.value = false
    isLoading.value = true
    await auth.login({
      username: email.value,
      password: password.value,
    })

    const loggedUser = auth.user.value
    if (loggedUser && loggedUser.isMustChangePassword) {
      toast.info(t('login.must-change-password'), { timeout: 3000 })
      router.push({ name: 'reset-password' })
      return
    }
  } catch (error) {
    toast.error(error.message || t('login.error'), {
      timeout: 3000,
    })
    console.log(error)
  } finally {
    hasLoaded.value = false
  }
}

const showPassword = ref(false)
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}
</script>

<style>
.password-field {
  display: flex;
  align-items: center;
}
.v-card {
  background-color: #f0f6f9;
}
.form {
  display: flex;
  align-items: center;
}

.forgot-password-link {
  color: grey;
  font-size: 12px;
}

.submit-button {
  width: 100%;
  margin-bottom: 10px;
}
</style>
