import { defineStore } from 'pinia'
import { ref } from 'vue'

import { ExamApi, AtenaApi } from '@/api-client'
import axiosInstance from '@/api/axios'

export const useAppStore = defineStore(
  'app',
  () => {
    const lang = ref('pt')
    const connected = ref(null)
    const onlineUsers = ref([])
    const userRole = ref('')
    const statusList = ref([])
    const singleSession = ref(true)
    const preventDefault = ref(false)
    const subdomain = computed(() => {
      return window.location.hostname.split('.')[0]
    })
    const isPiaui = computed(() => {
      return window.location.hostname.includes('piaui')
    })

    async function getStatus() {
      try {
        const response = await axiosInstance.get('/api/core/ExamSource/Exams/Status')

        statusList.value = response.data
      } catch (err) {
        console.error('Error fetching status:', err.message)
      }
    }

    return {
      isPiaui,
      connected,
      subdomain,
      lang,
      onlineUsers,
      userRole,
      getStatus,
      statusList,
      singleSession,
      preventDefault,
    }
  },
  {
    persist: [
      {
        paths: ['lang'],
        storage: localStorage,
      },
      {
        paths: ['lang'],
        storage: localStorage,
      },
    ],
  },
)
