import { RouteLocationRaw, Router } from 'vue-router'
import { useToast } from 'vue-toastification'

import { RequiredAuthOptions } from './types'

import { useAuth } from '@/auth/useAuth'

export function configureNavigationGuards(router: Router, options: RequiredAuthOptions) {
  const auth = useAuth()

  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const toast = useToast()

    // @ts-ignore
    const userRole = (auth.user.roles && auth.user.roles[0]) || 'visitor'

    // Rota de login
    if (to.name === options.loginRouteName) {
      if (auth.isAuthenticated) {
        console.log('🔒 Usuário já está autenticado. Redirecionando para rota inicial.')
        return { path: '/' }
      }
      console.log('🚪 Rota de login detectada, permitindo acesso.')
      return true
    }

    // Rota pública (como reset-password)
    if (to.meta.public) {
      console.log('🌐 Rota pública detectada, permitindo acesso:', to.name)
      return true
    }

    // Verifica se o usuário está autenticado para rotas restritas
    if (!auth.isAuthenticated) {
      console.log('❌ Acesso negado. Usuário não autenticado.')
      toast.info('Área restrita, faça login primeiro.', { timeout: 2000 })
      return { name: options.loginRouteName }
    }

    const roleRouteMap: { [key: string]: string[] } = {
      admin: ['/admin', '/access', '/masks', '/applications', '/users', '/sources', '/files', '/exams/details', '/'],
      source: ['/operational', '/files', '/exams/details', '/search-document', '/'],
      analyst: ['/analyst', '/'],
      physician: ['/radio-report-medico', '/exams-report-medico', '/'],
    }

    // Verifica se o papel do usuário tem rotas permitidas
    if (!roleRouteMap[userRole]) {
      console.log('⚠️ Acesso negado. Papel do usuário é inválido ou não permitido.')
      toast.error('Você não tem permissão para acessar esta página.', { timeout: 9000 })
      return { path: '/' }
    }

    // Função para verificar se a rota atual está entre as permitidas
    const isRouteAllowed = (route: string, allowedRoutes: string[]): boolean =>
      allowedRoutes.some((allowedRoute) => route.startsWith(allowedRoute))

    // Verifica se a rota atual é permitida para o papel do usuário
    const currentRoute = to.path
    if (!isRouteAllowed(currentRoute, roleRouteMap[userRole])) {
      console.log('⚠️ Acesso negado. Rota não permitida para o papel do usuário.')
      toast.error('Você não tem permissão para acessar esta página.', { timeout: 9000 })
      return { path: '/' }
    }

    // Redireciona para a rota raiz baseada no papel do usuário
    if (to.path === '/') {
      if (userRole === 'admin') {
        return { path: '/admin' }
      } else if (userRole === 'source') {
        return { path: '/operational' }
      } else if (userRole === 'analyst') {
        return { path: '/analyst' }
      } else if (userRole === 'physician') {
        // @ts-ignore
        if (auth.user.physician?.listExams) {
          return { path: '/radio-report-medico' }
          // @ts-ignore
        } else if (auth.user.physician?.onlyReports) {
          return { path: '/exams-report-medico' }
        } else {
          auth.logout()
          return { path: '/login' }
        }
      } else {
        auth.logout()
        return { path: '/login' }
      }
    }

    // Verifica se o usuário está tentando acessar uma rota de outro papel
    const adminRoutes = ['/admin', '/access', '/masks', '/applications', '/users', '/sources', '/files']
    const sourceRoutes = ['/operational', '/files', '/exams/details', '/search-document']
    const analystRoutes = ['/analyst']
    const physicianRoutes = ['/radio-report-medico', '/exams-report-medico']

    if (userRole === 'admin' && !adminRoutes.some((route) => to.path.startsWith(route))) {
      return { path: '/admin' }
    }
    if (userRole === 'source' && !sourceRoutes.some((route) => to.path.startsWith(route))) {
      return { path: '/operational' }
    }
    if (userRole === 'analyst' && !analystRoutes.some((route) => to.path.startsWith(route))) {
      return { path: '/analyst' }
    }
    if (userRole === 'physician' && !physicianRoutes.some((route) => to.path.startsWith(route))) {
      // @ts-ignore
      return { path: auth.user.physician?.listExams ? '/radio-report-medico' : '/exams-report-medico' }
    }

    return true
  })
}
