import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import { setDefaultOptions } from 'date-fns'
import { ptBR, enUS, es } from 'date-fns/locale'

import axiosInstance from '@/api/axios'
import { ExamApi } from '@/api-client'
import { useAppStore } from '@/stores/app'

export const useCategoryStore = defineStore(
  'category',
  () => {
    const items = ref([])
    const toast = useToast()
    const appStore = useAppStore()

    async function getAll() {
      try {
        const acceptLanguageMap = {
          pt: 'pt-BR',
          es: 'es-ES',
          en: 'en-US',
        }
        const acceptLanguage = acceptLanguageMap[appStore.lang] || 'en-US'

        const headers = {
          'Accept-Language': acceptLanguage,
        }
        axiosInstance.defaults.headers.common = { ...axiosInstance.defaults.headers.common, ...headers }
        const examApi = new ExamApi(undefined, undefined, axiosInstance)
        const response = await examApi.apiCoreExamSourceCategoryGet()
        items.value = response.data.data
      } catch (err) {
        console.error('Erro ao processar requisição:', err)
        toast.error('Erro ao carregar categorias', {
          timeout: 3000,
        })
      }
    }

    return {
      items,
      getAll,
    }
  },
  {
    persist: [
      {
        paths: ['items'],
        storage: localStorage,
      },
    ],
  },
)
